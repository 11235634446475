import React from "react"
import {Heading, type HeadingProps} from "@chakra-ui/react"

interface GradientHeaderProps extends HeadingProps {
  children: React.ReactNode
}

const GradientHeading = ({children, ...rest}: GradientHeaderProps) => (
  <Heading
    bgGradient="linear(to-l, #285E61, #319795)"
    bgClip="text"
    fontSize="3xl"
    fontWeight="extrabold"
    {...rest}
  >
    {children}
  </Heading>
)

export default GradientHeading
